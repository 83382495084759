import React, { useState, useEffect, useRef } from "react";
import "../patients.css";
import PatientsBanner from "./patientsBanner";
import Forms from "./forms";
import { useLocation, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// temp addition
import { SentForm } from "./EmailBox.js";
import getStartForms from "../Api/StartForm.js";
import EncounterSelectionList from "../PatientsListData/Encounter/EncounterSelectionList.js";
import Navbar from "../Nav/Navbar";
import EncounterErrorDialog from "./EncounterErrorDialog.js";
import { useAuth } from "../AppContextProvider.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import secrets from '../../env.json';
import getAllEncounters from "../Api/getAllEncounters.js";
import PatientEmailConfirmation from "./PatientEmailConfirmation.js";
import getPatientMetadata from "../Api/getPatientMetadata.js";
import getCclLanguageList from "../Api/getCclLanguageList.js";

function PatientDetails(props) {
  const location = useLocation();
  const history = useHistory();
  const { accessToken, userInfo } = useAuth();
  if (!location.state) {
    history.replace({
      pathname: "/patients",
    });
  }
  const {
    patientData = {},
    List = [],
    surveyData = {},
    selectionType = "",
    fin = ""
  } = location.state || {};

  const [formData, setFormData] = useState(surveyData);
  const [chosenForms, setChosenForms] = useState([]);
  const [openSentForm, setOpenSentForm] = useState(false);
  const [startSurveyLink, setStartSurveyLink] = useState();
  const [loader, setloader] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [showEncounterList, setShowEncounters] = useState(false);
  const [showLockScreenConfirmation, setShowLockScreenConfirmation] = useState(false);
  const [encounterError, setEncounterError] = useState(false);
  const [errorInstrument, setErrorInstrument] = useState([]);
  const [isSurvey, setIsSurvey] = useState(false);
  const [emailFormData, setEmailFormData] = useState({
    emailMessage: "",
    phoneMessage: "",
    phone: patientData?.phone.replace("(", "").replace(")", "").replace("-", ""),
    email: patientData?.email,
    contactTypePhone: false,
    contactTypeEmail: false,
    notifylogedinUser: false,
    notifyAdditionalParty: false,
    additionalPartyEmails: []
  });
  const [marginTop, setLookupHeight] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [currentEncounterSurvey, setCurrentEncounterSurvey] = useState("");
  const [patientInfo, setPatientInfo] = useState(patientData);
  const [encountersLoader, setEncountersLoader] = useState(false);
  const [locationType, setLocationType] = useState("");
  const [encounterTypes, setEncounterTypes] = useState();
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [registrationFormsList, setregistrationFormsList] = useState(false);
  const [patientMetadata, setPatientMetadata] = useState();
  const [startFormsParams, setStartFormsParams] = useState();
  const [interpretation, setInterpretation] = useState(false);
  const [showInterpretion, setShowInterpretion] = useState(false);
  const [cclLang,setCclLang]=useState()
  const chosenFormsRef = useRef([]);
  useEffect(() => {
    setFormData(surveyData)
  }, [surveyData])

  useEffect(() => {
    if(accessToken){
    getPatientMetadata(accessToken, patientData?.record_id).then((response) => {
      setPatientMetadata(response)
    })
      .catch((err) => {
        console.log(err)
      });
    
    getCclLanguageList(accessToken)
      .then((response)=>{
        setCclLang(response)
      })
      .catch((err) => {
        setloader(err);
        
      });
    }
  }, [])

  const setShowEncounterList = (val) => {
    setShowEncounters(val)
    if (!val) {
      setLocationType("");
      setEncounterTypes([]);
    }
  }
  const isSurveySelected = (pid, instrumentName) => {
    for (var i = 0; i < chosenForms.length; i++) {
      if (chosenForms[i].pid === pid && chosenForms[i].instrumentName === instrumentName) {
        return true;
      }
    }
    return false;
  }
  const alterChosenForms = (pid, instrumentName, instrumentLabel, isChecked, surveyType, surveyId, encounterTypes, isRegistrationForm,isInterpretationRequired) => {
    if (selectionType !== "encounter" && isChecked) {
      if (surveyType !== "") {
        var encTypes = [];
        if (encounterTypes !== null && encounterTypes !== "") {
          encTypes = encounterTypes.split(',')
        }
        setEncounterTypes(encTypes);
        setShowEncounterList(true);
        setIsSurvey(true);
        setLocationType(surveyType);
        var registrationFormVal = isRegistrationForm ? "1" : "0"
        var isInterpretationRequiredVal=isInterpretationRequired?"1":"0"
        setCurrentEncounterSurvey(pid + "-" + instrumentName + "-" + instrumentLabel + "-" + surveyType + "-" + surveyId + "-" + registrationFormVal+"-"+isInterpretationRequiredVal);
        return false;
      }
    }
    if (isChecked) {
      var newForm = {
        pid: pid,
        instrumentName: instrumentName,
        instrumentLabel: instrumentLabel,
        surveyType: surveyType,
        surveyId: surveyId,
        isRegistrationForm: isRegistrationForm,
        status: 1,
        isInterpretationRequired:isInterpretationRequired
      };
      setChosenForms((prevForms) => [...prevForms, newForm]);
    } else {
      var newArr = chosenForms.filter(
        (item) => item.pid !== pid || item.instrumentName !== instrumentName
      );
      setChosenForms(newArr);
    }
    return true;
  };
  const selectForm = (newForm) => {
    setChosenForms((prevForms) => {
      const formExists = prevForms.find(
        form => form.instrumentName === newForm.instrumentName && form.pid === newForm.pid
      );
      if (!formExists) {
        return [...prevForms, newForm];
      } else {
        return prevForms;
      };
    });
  };

  const updateFormsAfterEncounterChange = (availableForms) => {
    setChosenForms((prevForms) => {
      return prevForms.filter(
        form => availableForms.some(availableForm => availableForm.instrumentName === form.instrumentName && availableForm.pid === form.pid && availableForm.isAutoSelect)
      );
    });
  };
  useEffect(() => {

    if (chosenForms.length > 0) {
      setIsButtonActive(true);
    }
    else {
      setIsButtonActive(false);
    }
  }, [chosenForms]);

  const checkForms = () => {
    var tempArr = []
    var c = 0;
    if (selectionType !== "encounter") {
      for (var i = 0; i < chosenForms.length; i++) {
        if (chosenForms[i].surveyType !== "") {
          tempArr.push(chosenForms[i].instrumentLabel);
          c += 1;
        }
      }
    }
    if (c > 0) {
      setErrorInstrument(tempArr);
      setEncounterError(true);
      return false;
    }
    return true;
  }

  const openDialog = () => {
    if (isButtonActive) {
      if (!checkForms()) {
        return;
      }
      setOpenSentForm(!openSentForm);
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPhone = (phone) => {
    if (phone.length >= 10 && phone.length <= 11) {
      const numberRegex = /^\d+$/;
      return numberRegex.test(phone);
    }
    return false;
  }

  const checkAndShowLockScreenDialog = (val) => {
    if (checkForms()) {
      setShowLockScreenConfirmation(val);
    }
  }

  useEffect(() => {
    if (patientData && (patientData.encounter.length === 0 && patientData.appointment.length === 0)) {
      var mrn = patientData.mrn;
      if (fin != "") {
        mrn = ""
      }
      setEncountersLoader(true);
      const searchData = {
        lname: "",
        fname: "",
        fin: fin ? fin : "",
        dob: "",
        mrn: mrn,
        cernerEnv: localStorage.getItem('cernerENV')
      };
      getAllEncounters(searchData, accessToken)
        .then((response) => {
          var temp = structuredClone(patientData);
          temp.appointment = response.appointment;
          temp.encounter = response.encounter
          setPatientInfo(temp);
          var ind = List.indexOf(patientData);
          if (ind > -1) {
            List[ind] = temp;
          }
          setEncountersLoader(false);
        })
        .catch((err) => {

          setEncountersLoader(false);
        });
    }
  }, [patientData.mrn])

  const alterEncounterSurvey = (type, locationType) => {
    if (type) {
      var encounterSurvey = currentEncounterSurvey.split("-");
      if (encounterSurvey.length === 7) {
        if (locationType === encounterSurvey[3] || encounterSurvey[3] === "any") {
          var newForm = {
            pid: Number(encounterSurvey[0]),
            instrumentName: encounterSurvey[1],
            instrumentLabel: encounterSurvey[2],
            surveyType: encounterSurvey[3],
            surveyId: Number(encounterSurvey[4]),
            status: 1,
            isRegistrationForm: encounterSurvey[5] === "1" ? true : false,
            isInterpretationRequired: encounterSurvey[6] === "1" ? true : false
          };
          setChosenForms([...chosenForms, newForm]);
        }
      }
    }
    setCurrentEncounterSurvey("");
  }
  const startSurvey = (startType) => {
    if (isButtonActive) {
      if (!checkForms()) {
        return;
      }
      var fin = "";
      var apptType = "";
      var apptLocation = "";
      if (selectionType === "encounter" && patientData?.currentEncounter) {
        fin = patientData?.currentEncounter[0].fin_encounter;
      }
      else if (selectionType === "appointment") {
        fin = patientData?.currentAppointment[0].fin_appointment;
        apptType = patientData?.currentAppointment[0].appt_type;
        apptLocation = patientData?.currentAppointment[0].appt_location_unit;
      }
      if (startType === "send") {
        openDialog();
      }
      //setloader(true);
      const [datePart, timePart] = patientData?.birth_date.split(' ');
      const formattedDOB = `${datePart.substring(4, 8)}${datePart.substring(0, 2)}${datePart.substring(2, 4)}`;
      var startFormsParam = {
        recordId: patientData?.record_id,
        mrn: patientData?.mrn,
        fin: fin,
        patientFirstName: patientData?.name_first,
        patientLastName: patientData?.name_last,
        patient_dob: formattedDOB,
        language: patientData?.preferred_language,
        loggedInUsername: userInfo.email,
        startType: startType,
        chosenForms: chosenForms,
        personId: patientData?.person_id,
        apptType: apptType,
        apptLocation: apptLocation
      }
      if (startType === "send") {
        //Checking for contact details
        if (emailFormData.contactTypeEmail && !emailFormData.contactTypePhone) {
          startFormsParam.preferredContact = "email";
          if (!isValidEmail(emailFormData.email)) {
            alert("Please enter a valid patient email address.")
            return;
          }
          startFormsParam.email = emailFormData.email;
          setSuccessMessage("Email successfully sent.")
        }
        else if (emailFormData.contactTypePhone && !emailFormData.contactTypeEmail) {
          startFormsParam.preferredContact = "phone";
          if (!isValidPhone(emailFormData.phone)) {
            alert("Please enter a valid phone number.")
            return;
          }
          startFormsParam.phoneNumber = emailFormData.phone;
          setSuccessMessage("Message successfully sent.")
        }
        else if (emailFormData.contactTypeEmail && emailFormData.contactTypePhone) {
          startFormsParam.preferredContact = "both";
          if (!isValidEmail(emailFormData.email)) {
            alert("Please enter a valid patient email address.")
            return;
          }
          if (!isValidPhone(emailFormData.phone)) {
            alert("Please enter a valid phone number.")
            return;
          }
          startFormsParam.email = emailFormData.email;
          startFormsParam.phoneNumber = emailFormData.phone;
          setSuccessMessage("Email and Message successfully sent.");
        }
        else {
          alert("Please seclect a contact method.")
        }

        //checking for additional party params
        startFormsParam.notifyLoggedInUser = emailFormData.notifylogedinUser;
        if (emailFormData.notifyAdditionalParty) {
          startFormsParam.additionalPartyEmails = emailFormData.additionalPartyEmails.join(',');
        }
      }
      else {
        setSuccessMessage("Starting patient forms...")
      }
      setloader(true);

    };
    if (selectionType === "encounter") {
      startFormsParam = {
        ...startFormsParam,
        pmdName: patientData.currentEncounter[0].primary_provider_fname == null ? "" : patientData.currentEncounter[0].primary_provider_fname,
        pmdPhone: patientData.currentEncounter[0].primary_provider_phone == null ? "" : patientData.currentEncounter[0].primary_provider_phone,
        pmdFax: patientData.currentEncounter[0].primary_provider_fax == null ? "" : patientData.currentEncounter[0].primary_provider_fax,
        rmdName: patientData.currentEncounter[0].referring_provider_name == null ? "" : patientData.currentEncounter[0].referring_provider_name,
        rmdPhone: patientData.currentEncounter[0].referring_provider_phone == null ? "" : patientData.currentEncounter[0].referring_provider_phone,
        rmdFax: patientData.currentEncounter[0].referring_provider_fax == null ? "" : patientData.currentEncounter[0].referring_provider_fax
      };
    }
    startFormsParam.patientData = patientData;
    startFormsParam.startType = startType;
    const registrationForms = startFormsParam.chosenForms.filter(obj => obj.isRegistrationForm === true);
    let Interpretaion = false;
    for (let obj of startFormsParam.chosenForms) {
      if (obj.isInterpretationRequired === true && patientData.preferred_language!=="English") {
        Interpretaion = true;
        break;
      }
    }
   

    if (registrationForms.length > 0) {
      setregistrationFormsList(registrationForms);
      setShowEmailVerification(true);
      setInterpretation(Interpretaion)
      setloader(false);
      setStartFormsParams(startFormsParam);
      return;
    }
    if(Interpretaion){
      setShowEmailVerification(true);
      setInterpretation(Interpretaion)
      setShowInterpretion(true)
      setloader(false);
      setStartFormsParams(startFormsParam);
      return
    }
    startForms(startFormsParam);
  };

  const startForms = (startFormsParam) => {
    setloader(true);
    getStartForms(startFormsParam, accessToken)
      .then((response) => {
        setloader(false);
        setStartSurveyLink(response);
        setShowSuccessMessage(true);
        setTimeout(function () {
          if (startFormsParam.startType === "start") {
            localStorage.setItem("locked", true);
            window.open(response, '_blank', 'noopener,noreferrer');
          }
          history.replace('/');
          window.location.reload();
          //window.location.href = window.location.origin;
        }, 2000);
      })
      .catch((err) => {
        setloader(false);
      });
  }


  const constOnSavePatientEmailConfirmation = (email, confirmationVal) => {
    var reqBody = startFormsParams;
    if(confirmationVal){
      reqBody.patientData.email = email;
      reqBody.patientData.send_pdf_to_patient = "1";
    }
    else{
      reqBody.patientData.email = patientMetadata.email;
      reqBody.patientData.send_pdf_to_patient = "0";
    }
    if(interpretation ){
      setShowInterpretion(true)
      return
    }
    setShowEmailVerification(false);
    startForms(reqBody);
    
  }
  
  const startFormsOnPatientEmailConfirmationAndIterpretion=(interpretionData)=>{
    var reqBody = startFormsParams;
    reqBody.patientData.interpreter_language=(interpretionData.Lang=="Other" && interpretionData.OtherLang!="")?interpretionData.OtherLang:interpretionData.Lang
    reqBody.patientData.interpreter_id=interpretionData.ID
    reqBody.patientData.interpreter_required=interpretionData.InterpreterNeeded?"1":"0"
    reqBody.patientData.interpreter_type=interpretionData.InterpreterType
    reqBody.patientData.interpreter_name=interpretionData.Name
    reqBody.patientData.representative_name=interpretionData.Representative
    reqBody.patientData.interpreter_not_needed_reason=interpretionData.Reason
    reqBody.patientData.interpreter_email_required=interpretionData.InterpreterEmailRequired?"1":"0"
    reqBody.patientData.interpreter_email=interpretionData.InterpreterEmail
    setShowEmailVerification(false);
    startForms(reqBody);
  }

  const handleLockScreenStatus = (isLocked) => {
    startSurvey("start");
    setShowLockScreenConfirmation(false);
  }

  useEffect(() => {
    if (patientData.currentEncounter && chosenForms) {
      var encType = patientData.currentEncounter[0].location_facility === "CHLA" ? "inPatient" : "outPatient";
      var newArr = chosenForms.filter(
        (item) => item.surveyType === encType || item.surveyType === "" || item.surveyType === "any"
      );
      setChosenForms(newArr);
    }
  }, [patientData])

  return (
    <>
      {showEmailVerification && <PatientEmailConfirmation setShowConfirmationScreen={setShowEmailVerification} registrationFormsList={registrationFormsList} patientData={patientData} patientMetadata={patientMetadata} confirmEmail={constOnSavePatientEmailConfirmation} startSurveyForms={startFormsOnPatientEmailConfirmationAndIterpretion} showInterpretion={showInterpretion} setShowInterpretion={setShowInterpretion} cclLang={cclLang}/>}
      {showEncounterList &&
        <div id="overlay2">
          <EncounterSelectionList
            patientData={patientInfo}
            setShowEncounterList={setShowEncounterList}
            setIsSurvey={setIsSurvey}
            setDetailsLoader={setloader}
            alterEncounterSurvey={alterEncounterSurvey}
            setEncounterError={setEncounterError}
            isSurvey={isSurvey}
            encountersLoader={encountersLoader}
            locationType={locationType}
            encounterTypes={encounterTypes}
          />
        </div>
      }
      {showSuccessMessage &&
        <div id="overlay2">
          <div id="emailMessage">{successMessage}</div>
        </div>
      }

      {loader ?
        <div id="overlay">
          <div id="loading" className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div> : <></>}

      {encounterError &&
        <EncounterErrorDialog setEncounterError={setEncounterError} setShowEncounterList={setShowEncounterList} setIsSurvey={setIsSurvey} />
      }

      {showLockScreenConfirmation &&
        <div id="overlay2">
          <div id="lockedPopup">
            <div className="row">
              <div className="d-flex col-12 justify-content-center align-items-center">
                <span style={{ fontSize: "large", fontWeight: "bold", marginLeft: "5px" }}>
                  Do you want to lock your Screen
                  <i style={{ marginLeft: "5px", cursor: "pointer" }} title="Locks your screen when you switch tabs or reload" >
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </i>
                </span>
              </div>
            </div>
            <div className="row" style={{ marginTop: "30px" }}>
              <div className="col-6 d-flex justify-content-end">
                <div className="submit_button-pin" style={{ backgroundColor: "#004879" }} onClick={() => handleLockScreenStatus(true)}>
                  <div >Yes </div>
                </div>
              </div>
              <div className="col-6 d-flex justify-content-start">
                <div className="submit_button-pin" onClick={() => handleLockScreenStatus(false)}>
                  <div >No </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <PatientsBanner
        patientData={patientData}
        List={List}
        openDialog={openDialog}
        startSurvey={startSurvey}
        isButtonActive={isButtonActive}
        setShowEncounterList={setShowEncounterList}
        isSurvey={isSurvey}
        setIsSurvey={setIsSurvey}
        setShowLockScreenConfirmation={checkAndShowLockScreenDialog}
        setEncounterError={setEncounterError}
        setLookupHeight={setLookupHeight}
        handleLockScreenStatus={handleLockScreenStatus}
      />
      <div className="row m-0 d-flex" id="patientforms">
        <div className="col-lg-12 col-md-12 col-sm-12  w-100">
          <div
            className="submit_button_back  justify-content-right d-flex" style={{ marginTop }}
            id="cancelLookupOld"
          >
            <div
              onClick={(e) => {
                history.replace({
                  pathname: "/patients",
                  state: {
                    List: List, // Replace with your list of values
                    Fin: fin
                  },
                });
              }}
            >
              « Back to Lookup{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="tabs">
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="home" title="Patient Level Forms">
            <Forms
              formData={formData}
              alterChosenForms={alterChosenForms}
              recordId={patientData?.record_id}
              isSurveySelected={isSurveySelected}
              chosenForms={chosenForms}
              selectForms={selectForm}
              setChosenForms={setChosenForms}
              updateFormsAfterEncounterChange={updateFormsAfterEncounterChange}
            />
          </Tab>
          {/* <Tab eventKey="profile" title="Visits Level Form">
            Tab content for Profile
          </Tab> */}
        </Tabs>
      </div>
      {openSentForm && (
        <SentForm openDialog={openDialog} patientData={patientData} formData={emailFormData} setFormData={setEmailFormData} startSurvey={startSurvey} />
      )}
    </>
  );
}
export default PatientDetails;