import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import StartSurvey from './StartSurvey';
import SurveyStartVerification from './SurveyStartVerification';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import getSessionValidationInfo from '../Api/getSessionValidationInfo';
import logo from "../images/Chlalogo-01.png"

const Survey = () => {

    const [lang, setLang] = useState('');
    const [isStartForms, setIsStartForms] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isFormsAlreadyComplete, setIsFormAlreadyComplete] = useState(false);
    const queryParams = new URLSearchParams(useLocation().search);
    useEffect(() => {

        localStorage.setItem('sessionId', queryParams.get('id'));
        let sessionId = (queryParams).get('id')
        getSessionValidationInfo(sessionId)
            .then((response) => {
                if (response == null || response=="" || response==undefined) {
                    setIsFormAlreadyComplete(true);
                    setTimeout(() => {
                        setIsFormAlreadyComplete(false);
                        window.location.href = "https://www.chla.org";
                      }, 3000);
                }
                setLastName(response['lastName'])
                setFirstName(response['firstName'])
                setIsStartForms(response['isStartForm'])
                setLang(response["preferredLanguage"])
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);


    return (
        <>{!isFormsAlreadyComplete ?
            <>
                {isStartForms === true && <StartSurvey />}
                {isStartForms === false && <SurveyStartVerification firstName={firstName} lastName={lastName} lang={lang}/>}
            </> :
            <div>
                <div style={{ backgroundColor: '#004877' }}>
                    <img
                        style={{
                            height: '62px',
                            width: '300px',
                            padding: '10px'
                        }}
                        src={logo}
                        alt="Chlalogo"
                    />
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bold',
                        padding: '50px',
                        fontFamily: 'Arial, Helvetica, sans-serif',
                        fontSize: '20px'
                    }}
                >
                   Forms already completed
                </div>
            </div>}
        </>
    )
};

export default Survey;
