
import './SurveyStartVerification.css'
import translations from './LanguageConfig';
import React, { useEffect, useState,useRef } from 'react';
import logo from "../images/Chlalogo-01.png"
import getNextForm from '../Api/getNextForm';
import { Spinner} from "react-bootstrap";
import logInvalidationStatus from '../Api/logInvalidationStatus';

const SurveyStartVerification = (props) => {
    const [dateOfBirth, setDateOfBirth] = useState({ month: '', day: '', year: '' });
    const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
    const [isDayDropdownOpen, setIsDayDropdownOpen] = useState(false);
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
    const [lastName, setLastName] = useState('');
    const [inEng, setIneng] = useState(props.lang.toLowerCase() == "en"? true:false);
    const [submitButtonLoader,setSubmitButtonLoader]=useState(false);
    const inputDayDiv = useRef(null);
    const inputMonthDiv = useRef(null);
    const inputYearDiv = useRef(null);
    const {
        patient_last_name,
        patient_dob,
        patient_zipcode,
        birthdate_text,
        confirm_btn_text,
        not_recognized_text,
        not_recognized_confirm,
        legal_notice,
        months
    } = translations[inEng ? "English" : "Espanol"];
    const currentYear = new Date().getFullYear();
    const [startYear, setStartYear] = useState(Math.floor(currentYear/10)*10);
    const range = 10;
    const years = Array.from({ length: range }, (_, i) => startYear + i);

    useEffect(()=>{
        const LogInvalidationStatus = async () => {
        try {
            let sessionId=localStorage.getItem("sessionId")
            const response = await logInvalidationStatus(sessionId);
           
          } catch (err) {
            console.error('Error logInvalidationStatus:', err);
          }
        }
        LogInvalidationStatus()
    },[])
    useEffect
    (
      () => {
        document.addEventListener('mousedown', handleClickOutside);

      }, []);

  const handleClickOutside = (event) => {
    if (inputDayDiv.current && !inputDayDiv.current.contains(event.target)) {
     setIsDayDropdownOpen(false);
    }
    if (inputMonthDiv.current && !inputMonthDiv.current.contains(event.target)) {
        setIsMonthDropdownOpen(false);
       }
    if (inputYearDiv.current && !inputYearDiv.current.contains(event.target)) {
        setIsYearDropdownOpen(false);
       }
  };

    const getNextFormUrl=()=>{
        const day = `${dateOfBirth.day}`.length === 1 ? `0${dateOfBirth.day}` : dateOfBirth.day;
        const month = dateOfBirth.month.length === 1 ? `0${dateOfBirth.month}` : dateOfBirth.month;
        var requestBody = {
          sessionId: localStorage.getItem("sessionId"),
          dob: `${dateOfBirth.year}${month}${day}`,
          lName:lastName   
        };
         getNextForm(requestBody)
          .then((response) => {
            if (response.redirectURL === 'failed') {
                alert("Please Enter your Valid Details");
                setSubmitButtonLoader(false);
                return;
              } 
          
              window.location.href = response.redirectURL;
            })
            .catch((error) => {
              alert("Please Enter your Valid Details");
              setSubmitButtonLoader(false);
              return;
            });
        };

    const handlePrevRange = () => {
        setStartYear(prev => prev - range);
    };

    const handleNextRange = () => {
        setStartYear(prev => prev + range);
    };

    const handleConfirm = () => {
        setSubmitButtonLoader(true);
        if(dateOfBirth.day!=""&& dateOfBirth.month!="" && dateOfBirth.year!=""&& lastName.toLowerCase()!="")
            getNextFormUrl();
        else
        {alert("Please Enter your Valid Details");
            setSubmitButtonLoader(false);
         return;
        };
    };

    const handleDeny = () => {
        var answer = window.confirm("Press Ok if you don't recognize this person");
        if (answer) {
            window.location.href = "https://www.chla.org/";
        }
    };

    const handleMonthKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            const input = e.target.value;
            if (input.length === 2 && /^[0-9]*$/.test(input) && input <= 12) {
                setDateOfBirth({ ...dateOfBirth, month: input });
            }
            setIsMonthDropdownOpen(false);
        }
    };

    const handleDayKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            const input = e.target.value;
            if (input.length <= 2 && /^[0-9]*$/.test(input) && input<=31) {
                setDateOfBirth({ ...dateOfBirth, day: input });
            }
            setIsDayDropdownOpen(false);
        }
    };

    const handleYearKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            const input = e.target.value;
            if (input.length <= 4 && /^[0-9]*$/.test(input)) {
                setDateOfBirth({ ...dateOfBirth, year: input });
            }
            setIsYearDropdownOpen(false);
        }
    };

    const handleInputYearChange=(year)=>{
        const start = Math.floor(year / 10) * 10;
        setStartYear(start)
       
    }
    

    const dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
    return (
        <div id="patient_information_email">
            <div className="surveyHeader row m-0" style={{ backgroundColor: '#004877', width: '100%' }}>
                <div className="surveyImage col-lg-6 col-md-6 col-sm-8">
                    <img style={{ margin: '20px' }} id="logo" src={logo} alt="Logo" />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-4 surveyLangauge">
                    <div className="language" onClick={() => setIneng(!inEng)} id="lang-0">{inEng ? "Espanol" : "English"}</div>
                </div>
            </div>

            <div style={{ color: '#004877', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }} name="sm_trans_birthdate_text">
                {birthdate_text}
            </div>
            <div style={{ color: '#004877', fontWeight: 'bold', textAlign: 'center', marginBottom: '10px' }}>
                {props.firstName}&nbsp;{props.lastName}.
            </div>

            <div className="row m-3 d-flex justify-content-center " style={{ textAlign: 'center', margin: '5px', fontWeight: 'bolder' }}>
                <div className="col-lg-3 col-md-10 col-sm-12 ">
                    <div className="row m-0 d-flex" style={{ textAlign: 'left' }}>
                        <label className="col-12 d-flex p-0 pb-2">
                            <div name="sm_trans_birthdate">{patient_dob}</div>
                            <div style={{ color: 'red'}}>*</div>
                        </label>
                        <div className="d-flex p-0" style={{ gap: '10px', width: '100%' }}>
                            <div className="month-picker" ref={inputMonthDiv}>
                                <input
                                    type="text"
                                    value={dateOfBirth.month}
                                    placeholder="MM"
                                    className="finInput"
                                    onClick={(e) => { setIsMonthDropdownOpen(true);}}
                                    onFocus={() => setIsMonthDropdownOpen(true)}
                                    onChange={(e) => {
                                        var input = e.target.value;
                                        if (input.length <= 2 && /^[0-9]*$/.test(input) && input<=12) {
                                            setDateOfBirth({ ...dateOfBirth, month: input });
                                            setIsMonthDropdownOpen(true);
                                        }
                                    }}
                                    onKeyDown={handleMonthKeyDown}
                                />
                                {isMonthDropdownOpen && (
                                    <div className="month-dropdown">
                                        <div className='w-100 d-flex justify-content-center'>Months</div>
                                        <div className="month-dropdown-container">
                                            {months.map((month) => (
                                                <div
                                                    key={month.value}
                                                    className={`month-item ${parseInt(month.value) === parseInt(dateOfBirth.month) ? 'highlight' : ''}`}
                                                    onClick={(e) => { setIsMonthDropdownOpen(false); setDateOfBirth({ ...dateOfBirth, month: month.value }) }}
                                                >
                                                    {month.label}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="date-picker" ref={inputDayDiv}>
                                <input
                                    type="text"
                                    value={dateOfBirth.day}
                                    placeholder="DD"
                                    className="finInput"
                                    onClick={(e) => { setIsDayDropdownOpen(true);}}
                                    onFocus={() => setIsDayDropdownOpen(true)}
                                    onChange={(e) => {
                                        var input = e.target.value;
                                        if (input.length <= 2 && /^[0-9]*$/.test(input) && input<=31) {
                                            setDateOfBirth({ ...dateOfBirth, day: input });
                                            setIsDayDropdownOpen(true);
                                        }
                                    }}
                                    onKeyDown={handleDayKeyDown}
                                />
                                {isDayDropdownOpen && (
                                    <div className="date-dropdown">
                                        <div className='w-100 d-flex justify-content-center'>Days</div>
                                        <div className="date-dropdown-container">

                                            {dates.map((date) => (
                                                <div
                                                    key={date}
                                                    className={`date-item ${parseInt(date) === parseInt(dateOfBirth.day) ? 'highlight' : ''}`}
                                                    onClick={(e) => { setIsDayDropdownOpen(false); setDateOfBirth({ ...dateOfBirth, day: date }) }}   
                                                >
                                                    {date}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='year-picker' ref={inputYearDiv}>
                            <input
                                type="text"
                                value={dateOfBirth.year}
                                placeholder="YYYY"
                                className="finInput"
                                onClick={() => {setIsYearDropdownOpen(true);}}
                                onFocus={() => setIsYearDropdownOpen(true)}
                                onChange={(e) => {
                                    var input = e.target.value;
                                    if (input.length <= 4 && /^[0-9]*$/.test(input)) {
                                        setDateOfBirth({ ...dateOfBirth, year: input });
                                        setIsYearDropdownOpen(true);
                                    }
                                    if(input.length==4){
                                        handleInputYearChange(input)
                                    }
                                }}
                                onKeyDown={handleYearKeyDown}
                            />
                            {isYearDropdownOpen && (<div className='year-dropdown'>
                                <div className='year-dropdown-container'>
                                    <button onClick={handlePrevRange} className='yearToggleButton' >
                                        «
                                    </button>
                                    <span>{startYear} - {startYear + range - 1}</span>
                                    <button onClick={handleNextRange} className='yearToggleButton'>
                                        »
                                    </button>
                                </div>
                                <div style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(3, 1fr)',
                                    gap: '10px'
                                }}>
                                    {years.map((year) => (
                                        <div
                                            key={year}
                                            className={`year-item ${parseInt(year) === parseInt(dateOfBirth.year) ? 'highlight' : ''}`}
                                            onClick={() => {setIsYearDropdownOpen(false); setDateOfBirth({ ...dateOfBirth, year: year })}}
                                        >
                                            {year}
                                        </div>
                                    ))}
                                </div>
                            </div>)}

                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row m-3 d-flex justify-content-center " style={{ textAlign: 'center', margin: '5px', fontWeight: 'bolder' }}>
                <div className="col-lg-3 col-md-10 col-sm-12 ">
                    <div className="row m-0 d-flex" style={{ textAlign: 'left' }}>
                        <label className="col-12 d-flex p-0 pb-2">
                            <div name="sm_trans_patient_last_name_text">{patient_last_name}</div>
                            <div style={{ color: 'red' }}>*</div>
                        </label>
                        <div className="d-flex p-0" style={{ width: '100%' }}>
                            <input
                                className="finInput col-12 w-100"
                                style={{ overflow: 'hidden' }}
                                type="text"
                                id="lastname"
                                autoComplete="off"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row m-3 d-flex justify-content-center" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                <div className="col-lg-2 col-md-5 col-sm-5" name="sm_trans_confirm_button" id="confirm" onClick={handleConfirm}>
                {submitButtonLoader ? <Spinner size="sm" /> :confirm_btn_text}
                </div>
                <div name="sm_trans_legal_notice" style={{ padding: '20px' }}>
                    {legal_notice}
                </div>
                <div style={{ textAlign: 'center', color: '#383838', width: '100%', textDecoration: 'underline', cursor: 'pointer' }} id="deny" name="sm_trans_recognize_link" onClick={handleDeny}>
                    {not_recognized_text}
                </div>
                <a style={{ marginTop:'18px',fontWeight:'lighter', textAlign: 'center', width: '100%', textDecoration: 'underline', cursor: 'pointer' }} href='https://www.chla.org/notice-privacy-practices' target="_blank">
                Notice of Privacy Practices | Children's Hospital Los Angeles (chla.org)
                </a>
            </div>
        </div >
    );
};

export default SurveyStartVerification;



